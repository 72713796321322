import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Themed } from "theme-ui"
import Menu from "./menu"
import getSlug from "speakingurl"

const MenuStudentContent = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query MenuStudent {
        dataJson(slug: {eq: "menu-student"}) {
          fragment_1
          fragment_2
          fragment_3
          fragment_4
          fragment_5
          fragment_6
          fragment_7
          fragment_8
          fragment_9
          fragment_10
          fragment_11
          fragment_12
          fragment_50
        }
      }
    `
  )

  const dataFragments = data.dataJson
  const slugFragment1 = getSlug(dataFragments.fragment_1)
  const totalNumberTasks = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  const textColor = props.textColor ? props.textColor : `primarydarken`
  const slugFragment50 = getSlug(dataFragments.fragment_50)

  return (
    <Box
      as='div'
      className='menu'
      sx={{
        ".menu-items": {
          margin: 0,
          padding: 0,
        },
        li: {
          fontSize: 1,
          listStyle: `none`,
          marginTop: 1,
          marginBottom: 1,
        },
        h3: {
          color: `black`,
          fontSize: 1,
          borderBottom: `1px solid black`,
          paddingBottom: 1,
          marginBottom: 1,
        },
        a: {
          color: `black`,
          borderBottom: `none`,
          fontSize: 1,
          textDecoration: `none`,
          ":hover": {
            color: `${textColor}`,
          },
        },
        ".menu-container": {
          marginBottom: 3,
        }
      }}
    >
      <Box className="menu-container">
        <Menu title={dataFragments.fragment_3}>
        <Themed.ul className="menu-items">
          {totalNumberTasks.map((item, index) =>(
            <Themed.li key={index}>
              <Themed.a
                as={Link}
                to={`/${slugFragment1}-${item}`}
                rel="bookmark"
              >
                {dataFragments.fragment_2} {item}
              </Themed.a>
            </Themed.li>
          ))}
        </Themed.ul>
        </Menu>
      </Box>

      <Box className="menu-container">
        <Menu title={dataFragments.fragment_4}>
        <Themed.ul className="menu-items">
          <Themed.li>
            <Themed.a
              as={Link}
              to={`/${dataFragments.fragment_6}`}
            >
              {dataFragments.fragment_5}
            </Themed.a>
          </Themed.li>
          <Themed.li>
            <Themed.a
              as={Link}
              to={`/${dataFragments.fragment_8}`}
            >
              {dataFragments.fragment_7}
            </Themed.a>
          </Themed.li>
          <Themed.li>
            <Themed.a
              as={Link}
              to={`/${dataFragments.fragment_10}`}
            >
              {dataFragments.fragment_9}
            </Themed.a>
          </Themed.li>
          <Themed.li>
            <Themed.a
              as={Link}
              to={`/${dataFragments.fragment_12}`}
            >
              {dataFragments.fragment_11}
            </Themed.a>
          </Themed.li>
        </Themed.ul>
        </Menu>
      </Box>

      <Themed.p><Themed.a as={Link} to={`/${slugFragment50}`}>{dataFragments.fragment_50} &#x02192;</Themed.a></Themed.p>

    </Box>
  )
}

export default MenuStudentContent