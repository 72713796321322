import React from "react"
import { Grid, Themed } from "theme-ui"

const LayoutGrid = ( props ) => {

  return (
    <Themed.root>
      <Grid
        as={`div`}
        gap={0}
        sx={{
          backgroundColor: `white`,
          gridTemplateColumns: [  `1fr`,
                                  `1fr`,
                                  `1fr`,
                                  `25vw 1fr`,
                                  `25vw 1fr`,
                                  `25vw 1fr`
                                ],
          gridTemplateRows: `auto`,
          gridTemplateAreas: [  `"main"`,
                                `"main"`,
                                `"main"`,
                                `"aside main"`,
                                `"aside main"`,
                                `"aside main"`
                              ],
          width: `100vw`,
          ".section-aside": {
            gridArea: `aside`,
            height: [`auto`, `auto`, `auto`, `100vh`, `100vh`, `100vh`],
            position: [`inherit`, `inherit`, `inherit`, `fixed`, `fixed`, `fixed`],
            width: [`100vw`, `100vw`, `100vw`, `25vw`, `25vw`, `25vw`],
          },
          main: {
            gridArea: `main`,
            minHeight: `100vh`,
            marginTop: 4,
            px: [3, 3, 3, 4, 4, 4],
            pb: 3,
          }
        }}
      >
        {props.children}
      </Grid>
    </Themed.root>
  )

}

export default LayoutGrid