import React, { useState } from "react"
import { Box, Themed } from "theme-ui"

const Menu = ( props ) => {

  const [isActive, setIsActive] = useState(false);

  return (
    <Box className="menu">
      <Box className="menu-title" onClick={() => setIsActive(!isActive)}>
        <Themed.h3>{props.title} {isActive ? '-' : '+'}</Themed.h3>
      </Box>
      {isActive &&
        <Box className="menu-content">{props.children}</Box>
      }
    </Box>
  );

};

export default Menu
