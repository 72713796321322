import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Themed } from "theme-ui"

const ButtonBack = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query ButtonBack {
        dataJson(slug: {eq: "button-back"}) {
          fragment_1
          fragment_2
        }
      }
    `
  )

  const dataFragments = data.dataJson
  const textColor = props.textColor ? props.textColor : `primarydarken`

  return (
    <Box
      as='div'
      className=''
      sx={{
        a: {
          color: `black`,
          display: `inline-block`,
          marginTop: 4,
          textDecoration: `none`,
          ":hover": {
            color: `${textColor}`,
          }
        }
      }}
    >
      <Themed.a
        as={Link}
        to={`/${dataFragments.fragment_1}`}
        rel="bookmark"
      >
        &#8592; {dataFragments.fragment_2}
      </Themed.a>
    </Box>
  )
}

export default ButtonBack