import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Grid, Themed } from "theme-ui"
import BgStudent from "../../images/bg-alumnado.png"
import BgTeacher from "../../images/bg-profesorado.png"

const Aside = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query AsideInteractiveSpace {
        site {
          siteMetadata {
            title
            descriptionCustom
          }
        }
      }
    `
  )

  const textColor = props.textColor ? props.textColor : `gray.6`
  const backgroundImage = ( props.bgImage === 'student') ? BgStudent : BgTeacher

  return (
    <Grid
      className={`section-aside`}
      sx={{
        backgroundColor: `#fefbf6`,
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: `bottom left`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `300px auto`,
        gridTemplateColumns: `1fr`,
        gridTemplateRows: [ `auto`,
                            `auto`,
                            `auto`,
                            `3rem 1fr`,
                            `3rem 1fr`,
                            `3rem 1fr`
                          ],
        py: 4,
        px: [3, 3, 3, 3, 4, 4],
        ".title-area": {
          display: [`none`, `none`, `none`, `initial`, `initial`, `initial`],
          fontSize: 2,
          lineHeight: `body`,
          a: {
            color: `${textColor}`,
            borderBottom: `none`,
            fontFamily: `body`,
            ":hover": {
              color: `${textColor}`,
            },
          },
          p: {
            color: `black`,
            fontSize: 0,
            margin: 0,
          }
        },
      }}
    >
      <Box className="title-area">
        <Link to="/" rel="bookmark">{data.site.siteMetadata.title}</Link>
        <Themed.p dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.descriptionCustom}} />
      </Box>
      <Box
        className="content-aside"
        sx={{
          variant: 'styles',
          alignSelf: `flex-start`,
        }}
      >
        {props.children}
      </Box>
    </Grid>
  )

}

export default Aside