import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Button, Label, Input } from "theme-ui"

const Form = () => {

  const data = useStaticQuery(
    graphql`
      query FormSendVideo {
        dataJson(slug: {eq: "form-send-video"}) {
          fragment_1
          fragment_2
          fragment_3
          fragment_4
          fragment_5
          fragment_6
          fragment_7
          fragment_8
        }
      }
    `
  )

  const dataFragments = data.dataJson

  return (
    <Box
      as={`form`}
      className="form"
      action="https://getform.io/f/5db9f713-b461-4c77-91c3-c7c5cf3b750a" method="POST" enctype="multipart/form-data"
      sx={{
        "input[type='file']": {
          display: `none`
        },
        ".custom-file-upload": {
          padding: 2,
          border: `1px solid black`,
          marginBottom: 3,
        }
      }}
    >
      <Label htmlFor='username'>{dataFragments.fragment_1}</Label>
      <Input
        type='text'
        name='username'
        id='username'
        mb={3}
      />
      <Label className="custom-file-upload">
        <Input
          type="file"
          name="file"
        />
        {dataFragments.fragment_2}
      </Label>
      <Button
        variant='secondary'
        type='submit'
      >
        {dataFragments.fragment_3}
      </Button>
    </Box>
  )

}

export default Form
