import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Themed } from "theme-ui"
import Menu from "./menu"

import getSlug from "speakingurl"

const MenuTeacherContent = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query MenuTeacher {
        dataJson(slug: {eq: "menu-teacher"}) {
          fragment_1
          fragment_2
          fragment_3
          fragment_4
          fragment_5
          fragment_6
          fragment_7
          fragment_8
          fragment_9
          fragment_10
          fragment_11
          fragment_12
          fragment_13
          fragment_14
          fragment_15
          fragment_16
          fragment_17
          fragment_18
          fragment_19
          fragment_20
          fragment_50
          fragment_51
        }
      }
    `
  )

  const dataFragments = data.dataJson

  const contentMenu = [
    [ getSlug(dataFragments.fragment_2), dataFragments.fragment_2 ],
    [ getSlug(dataFragments.fragment_3), dataFragments.fragment_3 ],
    [ getSlug(dataFragments.fragment_4), dataFragments.fragment_4 ],
    [ getSlug(dataFragments.fragment_5), dataFragments.fragment_5 ],
    [ getSlug(dataFragments.fragment_6), dataFragments.fragment_6 ],
    [ getSlug(dataFragments.fragment_7), dataFragments.fragment_7 ]
  ]

  const slugFragment9 = getSlug(dataFragments.fragment_9)
  const textColor = props.textColor ? props.textColor : `primarydarken`

  return (
    <Box
      as='div'
      className='menu'
      sx={{
        ".menu-items": {
          margin: 0,
          padding: 0,
        },
        li: {
          fontSize: 1,
          listStyle: `none`,
          marginTop: 1,
          marginBottom: 1,
        },
        h3: {
          color: `black`,
          fontSize: 1,
          borderBottom: `1px solid black`,
          paddingBottom: 1,
          marginBottom: 1,
        },
        a: {
          color: `black`,
          borderBottom: `none`,
          fontSize: 1,
          textDecoration: `none`,
          ":hover": {
            color: `${textColor}`,
          },
        },
        ".menu-container": {
          marginBottom: 3,
        }
      }}
    >

      <Box className="menu-container">
        <Menu title={dataFragments.fragment_1}>
        <Themed.ul className="menu-items">
          {contentMenu.map((item, index) => (
            <Themed.li key={index}>
              <Themed.a
                as={Link}
                to={`/${dataFragments.fragment_8}-${item[0]}`}
                rel="bookmark"
              >
                {item[1]}
              </Themed.a>
            </Themed.li>
          ))}
        </Themed.ul>
        </Menu>
      </Box>

      <Box className="menu-container">
        <Menu title={dataFragments.fragment_11}>
        <Themed.ul className="menu-items">
        {[...Array(9)].map((item, i) => 
          <Themed.li key={i}>
            <Themed.a
              as={Link}
              to={`/${slugFragment9}-${i+1}`}
              rel="bookmark"
            >
              {dataFragments.fragment_10} {i+1}
            </Themed.a>
          </Themed.li>
        )}
        </Themed.ul>
        </Menu>
      </Box>

      <Box className="menu-container">
        <Menu title={dataFragments.fragment_12}>
        <Themed.ul className="menu-items">
          <Themed.li>
            <Themed.a
              as={Link}
              to={`/${dataFragments.fragment_14}`}
            >
              {dataFragments.fragment_13}
            </Themed.a>
          </Themed.li>
          <Themed.li>
            <Themed.a
              as={Link}
              to={`/${dataFragments.fragment_16}`}
            >
              {dataFragments.fragment_15}
            </Themed.a>
          </Themed.li>
          <Themed.li>
            <Themed.a
              as={Link}
              to={`/${dataFragments.fragment_18}`}
            >
              {dataFragments.fragment_17}
            </Themed.a>
          </Themed.li>
          <Themed.li>
            <Themed.a
              as={Link}
              to={`/${dataFragments.fragment_20}`}
            >
              {dataFragments.fragment_19}
            </Themed.a>
          </Themed.li>
        </Themed.ul>
        </Menu>
      </Box>

      <Themed.p><Themed.a as={Link} to={`/${dataFragments.fragment_50}`}>{dataFragments.fragment_51} &#x02192;</Themed.a></Themed.p>
      
    </Box>
  )
}

export default MenuTeacherContent