import React from "react"
import { graphql } from "gatsby"
import LayoutGrid from "../components/interactive-space/layout-grid"
import Aside from "../components/interactive-space/aside"
import ButtonBack from "../components/interactive-space/button-back"
import MenuStudent from "../components/interactive-space/menu-student"
import MenuTeacher from "../components/interactive-space/menu-teacher"
import { Box, Themed } from "theme-ui"
import { useMediaQuery } from "react-responsive"
import { lighten } from "@theme-ui/color"
import Form from "../components/interactive-space/form"

const PagesInteractiveSpace = ({ data }) => {

  const pageInteractiveSpace = data.markdownRemark
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 })
  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 })

  return (
    <LayoutGrid>
      {(pageInteractiveSpace.frontmatter.template === `student` && isDesktopOrLaptop)  &&
        <Aside textColor="guatemala1" bgImage="student">
          <MenuStudent textColor="guatemala1" />
          <ButtonBack textColor="guatemala1" />
        </Aside>
      }
      {(pageInteractiveSpace.frontmatter.template === `teacher` && isDesktopOrLaptop)  &&
        <Aside textColor="honduras1" bgImage="teacher">
          <MenuTeacher textColor="honduras1" />
          <ButtonBack textColor="honduras1" />
        </Aside>
      }
      <Box
        as={`main`}
        className="content"
        sx={{
          maxWidth: [`100vw`, `100vw`, `100vw`, `50vw`, `50vw`, `45vw`],
          h1: {
            color: `gray.6`,
            fontSize: [3, 3, 3, 6, 7, 7],
            "&:after": {
              backgroundColor: lighten(`primary`, 0.4),
              content: `""`,
              display: `block`,
              height: `1px`,
              my: 3,
              width: 6,
            }
          },
          "p:first-of-type": {
            marginTop: 0,
          }
        }}
      >
        <Themed.h1 dangerouslySetInnerHTML={{ __html: pageInteractiveSpace.frontmatter.title }} />
        <Box
          sx={{
            variant: `styles`,
            "h2, h3, h4, h5, h6": {
              color: `gray.5`,
            },
            "p, ul, li, table": {
              color: `black`,
            },
            a: {
              color: `black`,
              borderBottomWidth: `1px`,
              borderBottomStyle: `dotted`,
              "&:hover, &:active, &:focus": {
                color: `primary`
              }
            }
          }}
          dangerouslySetInnerHTML={{ __html: pageInteractiveSpace.html }}
        />
        {pageInteractiveSpace.frontmatter.slug === 'alumnado-tarea-global' &&
          <Box
            sx={{
              "label": {
                color: `black`,
              }
            }}
          >
            <Form />
          </Box>
        }
        {isTabletOrMobileDevice &&
          <Box
            sx={{
              marginTop: 4,
            }}
          >
            {pageInteractiveSpace.frontmatter.template === `student` &&
              <MenuStudent />
            }
            {pageInteractiveSpace.frontmatter.template === `teacher` &&
              <MenuTeacher />
            }
            <ButtonBack />
          </Box>
        }
      </Box>
    </LayoutGrid>
  )

}

export default PagesInteractiveSpace

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(format: PLAIN)
      frontmatter {
        template
        title
        slug
      }
    }
  }
`
